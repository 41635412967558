export const MenuPlusIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5 19.72H4.5V7.5H11.5V5.5H4.5C3.4 5.5 2.5 6.4 2.5 7.5V19.5C2.5 20.6 3.4 21.5 4.5 21.5H16.5C17.6 21.5 18.5 20.6 18.5 19.5V12.5H16.5V19.72Z" fill="white" />
            <path d="M18.5 2.5H16.5V5.5H13.5C13.51 5.51 13.5 7.5 13.5 7.5H16.5V10.49C16.51 10.5 18.5 10.49 18.5 10.49V7.5H21.5V5.5H18.5V2.5Z" fill="white" />
            <path d="M14.5 9.5H6.5V11.5H14.5V9.5Z" fill="white" />
            <path d="M6.5 12.5V14.5H14.5V12.5H6.5Z" fill="white" />
            <path d="M14.5 15.5H6.5V17.5H14.5V15.5Z" fill="white" />
        </svg>
    )
}