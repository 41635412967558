import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import AppRouter from "./app/app-router.component.tsx";
import { MatomoProvider, MatomoProviderConfig } from "@keiko-app/react-matomo";
import * as Sentry from "@sentry/browser";

import "nprogress/nprogress.css";
import "react-toastify/dist/ReactToastify.css";

import "./index.css";
import {
  GLITCHTIP_ENABLED,
  GLITCHTIP_DSN,
  GLITCHTIP_ENVIRONMENT,
  MATOMO_SITE_ID,
  MATOMO_URL,
} from "./shared/constants/api.tsx";
import { Theme } from "@radix-ui/themes";

const config: MatomoProviderConfig = {
  trackerBaseUrl: MATOMO_URL,
  siteId: MATOMO_SITE_ID,
};

Sentry.init({
  dsn: GLITCHTIP_DSN,
  environment: GLITCHTIP_ENVIRONMENT,
  enabled: GLITCHTIP_ENABLED,
});

createRoot(document.getElementById("root")!).render(
  MATOMO_URL ? (
    <MatomoProvider config={config}>
      <Theme>
        <AppRouter />
      </Theme>
    </MatomoProvider>
  ) : (
    <Theme>
      <AppRouter />
    </Theme>
  )
);
