import { Outlet, useLocation } from "react-router-dom";
import CustomSidebar from "./sidebar/custom-sidebar";
import BottomNav from "./bottomNav";
import useAuthStore from "~/store/auth.store";
import { networkService } from "~/services";
import { LOGOUT_API, PROFILE_INFO_API } from "../constants/api";
import { useEffect, useState } from "react";
import useSidebarStore from "~/store/sidebar.store";
import { UserRole } from "../config";
import { handleError } from "../utils/errors.util";
import useWarningModalStore from "~/store/warning.store";
import { UserRound } from "lucide-react";
import { HomeIcon } from "lucide-react";
import { HomeSolidIcon } from "../icon/home-solid-icon";
import { LicenseSolidIcon } from "../icon/license-solid-icon";
import { LicenseThinIcon } from "../icon/license-thin-icon";
import { ProfileThinIcon } from "../icon/profile-thin-icon";
import { ProfileSolidIcon } from "../icon/profile-solid-icon";
import { HomeThinIcon } from "../icon/home-thin-icon";

export default function SidebarLayout() {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const { pathname } = useLocation();
  const [collapsed, setIsCollapsed] = useSidebarStore((state) => [
    state.collapsed,
    state.setCollapsed,
  ]);
  const [accessToken, user, setAuthState, setUserInfo] = useAuthStore(
    (state) => [
      state.accessToken,
      state.user,
      state.setAuthState,
      state.updateUserInfo,
    ]
  );
  const [clearAllWarningState] = useWarningModalStore((state) => [
    state.clearAllWarningState,
  ]);

  useEffect(() => {
    if (!accessToken) return;
    const fetchUserProfile = async () => {
      try {
        const response = await networkService.get<any>(
          `${PROFILE_INFO_API}/${user?.userId}`
        );
        setUserInfo({
          email: response.data.email,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          emailVerified: response.data.emailVerified,
          organizations: response.data.organizations,
        });
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };

    fetchUserProfile();
  }, [accessToken, setUserInfo, user?.userId]);

  const handleLogout = async () => {
    try {
      await networkService.post(LOGOUT_API, {});
      setAuthState();
      clearAllWarningState();
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    }
  };

  useEffect(() => {
    const initialHeight = window.innerHeight;

    const handleFocusIn = (e: FocusEvent) => {
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement
      ) {
        const viewportHeight = window.visualViewport?.height || 0;
        const isKeyboardActive =
          viewportHeight < initialHeight - 100; // Adjust threshold
        setIsKeyboardVisible(isKeyboardActive);
      }
    }

    const handleFocusOut = () => {
      setIsKeyboardVisible(false);
    };

    window.addEventListener("focusin", handleFocusIn);
    window.addEventListener("focusout", handleFocusOut);

    return () => {
      window.removeEventListener("focusin", handleFocusIn);
      window.removeEventListener("focusout", handleFocusOut);
    };
  }, []);

  return (
    <div
      className={`max-w-screen flex h-[100dvh] max-h-screen overflow-hidden md:flex-row ${user?.role === UserRole.Admin ? "" : "flex-col"}`}>
      {/* <Sidebar /> */}
      <div
        className={`${user?.role === UserRole.Admin ? "" : "hidden md:block"}`}>
        <CustomSidebar
          pathname={pathname}
          collapsed={collapsed}
          setIsCollapsed={setIsCollapsed}
          toProfileRoute="/profile"
          handleLogout={handleLogout}
          userProfile={user}
          heroFullIcon={
            <div className="flex h-16 shrink-0 items-center mb-0 pt-2">
              <img
                alt="HeartFocus logo"
                src="/black.svg"
                className="h-8 w-auto"
                width={20}
                height={22}
              />
              {!collapsed && (
                <h3 className="ml-4 text-neutral-900 font-bold text-2xl leading-normal font-space-grotesk">
                  Portal
                </h3>
              )}
            </div>
          }
          isActiveClassName="bg-[#EDEDFA]"
          containerClassName="font-space-grotesk"
          collapseButtonClassName="bg-white-a700"
          heroCollapsedIcon={
            <img
              alt="HeartFocus logo"
              src="/black.svg"
              className="h-8 w-auto"
              width={20}
              height={22}
            />
          }
          navigationItems={[
            {
              to: "/dashboard",
              label: "Home",
              isActive: pathname.includes("/dashboard"),
              fullIcon: <HomeThinIcon width={18} height={16}/>,
              navigationExclusiveToRole: [UserRole.Member],
              activeIcon: <HomeSolidIcon width={18} height={16}/>,
              collapsedIcon: <HomeThinIcon width={18} height={16}/>,
            },
            {
              to: "/home",
              label: "Home",
              isActive: pathname.includes("/home"),
              fullIcon: <img src="/menu/plans.svg" alt="Plan" />,
              navigationExclusiveToRole: [UserRole.Admin],
              activeIcon: <img src="/menu/plans-active.svg" alt="Plan" />,
              collapsedIcon: <img src="/menu/plans.svg" alt="Plan" />,
            },
            {
              to: "/customers",
              label: "Customers",
              isActive: pathname.includes("/customers"),
              fullIcon: <img src="/menu/customers.svg" alt="Customers" />,
              navigationExclusiveToRole: [UserRole.Admin],
              activeIcon: (
                <img src="/menu/customers-active.svg" alt="Customers" />
              ),
              collapsedIcon: <img src="/menu/customers.svg" alt="Customers" />,
            },
            // {
            //   to: "/probes",
            //   label: "Probes",
            //   isActive: pathname.includes("/probes"),
            //   fullIcon: <img src="/menu/probes.svg" alt="Probes" />,
            //   navigationExclusiveToRole: [],
            //   activeIcon: <img src="/menu/probes-active.svg" alt="Probes" />,
            //   collapsedIcon: <img src="/menu/probes.svg" alt="Probes" />,
            // },
            {
              to: "/licenses",
              label: "Licenses",
              isActive:
                pathname.includes("/licenses") || pathname.includes("/probes"),
              fullIcon: <LicenseThinIcon width={'20'} height={'18'}/>,
              navigationExclusiveToRole: [],
              activeIcon: <LicenseSolidIcon width={'20'} height={'18'}/>,
              collapsedIcon: <LicenseThinIcon width={'20'} height={'18'}/>,
            },
            // {
            //   to: "/payment",
            //   label: "Payment",
            //   isActive: pathname.includes("/payment"),
            //   fullIcon: <img src="/menu/payment.svg" alt="Payment" />,
            //   navigationExclusiveToRole: [],
            //   activeIcon: <img src="/menu/payment-active.svg" alt="Payment" />,
            //   collapsedIcon: <img src="/menu/payment.svg" alt="Payment" />,
            // },
            {
              to: "/manufacturer",
              label: "Manufacturer",
              isActive: pathname.includes("/manufacturer"),
              fullIcon: <img src="/menu/manufacturer.svg" alt="Manufacturer" />,
              navigationExclusiveToRole: [UserRole.Admin],
              activeIcon: (
                <img src="/menu/manufacturer-active.svg" alt="Manufacturer" />
              ),
              collapsedIcon: (
                <img src="/menu/manufacturer.svg" alt="Manufacturer" />
              ),
            },
            // {
            //   to: "/reports",
            //   label: "Reports",
            //   isActive: pathname.includes("/reports"),
            //   fullIcon: <img src="/menu/reports.svg" alt="Reports" />,
            //   navigationExclusiveToRole: [UserRole.Admin],
            //   activeIcon: <img src="/menu/reports-active.svg" alt="Reports" />,
            //   collapsedIcon: <img src="/menu/reports.svg" alt="Reports" />,
            // },
          ]}
        />
      </div>

      <div className="flex-grow overflow-auto transition-all duration-300">
        <Outlet />
      </div>

      <div
        className={`${user?.role === UserRole.Admin ? "hidden" : "md:hidden"} ${isKeyboardVisible ? "hidden" : ""}`}>
        <BottomNav
          navigationItems={[
            {
              to: "/dashboard",
              label: "Home",
              isActive: pathname.includes("/dashboard"),
              fullIcon: <HomeThinIcon width={27} height={24}/>,
              navigationExclusiveToRole: [UserRole.Member],
              activeIcon: <HomeSolidIcon width={27} height={24}/>,
            },
            {
              to: "/licenses",
              label: "Licenses",
              isActive:
                pathname.includes("/licenses") || pathname.includes("/probes"),
              fullIcon: <LicenseThinIcon/>,
              navigationExclusiveToRole: [],
              activeIcon: <LicenseSolidIcon/>,
            },
            {
              to: `/profile/${user?.userId}`,
              label: "Profile",
              isActive: pathname.includes("/profile"),
              fullIcon: <ProfileThinIcon/>,
              navigationExclusiveToRole: [UserRole.Admin, UserRole.Member],
              activeIcon: <ProfileSolidIcon/>,
            },
          ]}
          pathname={pathname}
          setIsCollapsed={setIsCollapsed}
          handleLogout={handleLogout}
        />
      </div>
    </div>
  );
}
