import React, { useEffect, useState } from "react";

interface CarouselProps {
  images: string[];
  interval?: number;
  width?: string;
  height?: string;
}

const Carousel: React.FC<CarouselProps> = ({ images, interval = 3000, width="100%", height="326px" }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const switchImage = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const intervalId = setInterval(switchImage, interval);

    return () => clearInterval(intervalId);
  }, [images.length, interval]);

  return (
    <div className="relative overflow-hidden rounded-lg" style={{ width, height, objectFit: "fill" }}>
      {images.map((src, index) => (
        <div
          key={index}
          className={`absolute inset-0 transition-all duration-1000 ease-in-out flex justify-center items-center ${index === currentIndex ? "opacity-100 z-10" : "opacity-0 z-0"
            }`}
          style={{ transitionDuration: `${interval / 3}ms` }}
        >
          <img
            src={src}
            alt={`Slide ${index}`}
            className="rounded-lg object-cover h-fit"
          />
        </div>
      ))}
    </div>
  );
};

export default Carousel;
