import { ErrorMessage, Field, Form, Formik } from "formik";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import networkService from "~/services/network.service";
import PageHeader from "~/shared/components/page-header.component";
import { PROFILE_INFO_API } from "~/shared/constants/api";
import { handleError } from "~/shared/utils/errors.util";
import useAuthStore from "~/store/auth.store";
import useSidebarStore from "~/store/sidebar.store";
import { UserRole } from "~/shared/config";
import { Label } from "~/shared/components/ui/label";
import { ChevronLeft } from "lucide-react";

const UpdateProfile = () => {
  const navigate = useNavigate();
  const [accessToken, user, setUserInfo] = useAuthStore((state) => [
    state.accessToken,
    state.user,
    state.updateUserInfo,
  ]);
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);

  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await networkService.get<any>(
        `${PROFILE_INFO_API}/${user?.userId}`
      );
      setUserInfo({
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        emailVerified: response.data.emailVerified,
      });
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    }
  }, [user?.userId, setUserInfo]);

  useEffect(() => {
    if (!accessToken) return;
    fetchUserDetails();
  }, [accessToken, fetchUserDetails]);

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    if (!user?.userId || !accessToken) return;

    const updateData = {
      firstName: values.firstName,
      lastName: values.lastName,
    };

    try {
      await networkService.put<any>(`${PROFILE_INFO_API}/update`, updateData);
      await fetchUserDetails();
      toast.success("Profile updated successfully");
      navigate(-1);
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  const updateUserSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, "First name must be at least 6 characters")
      .required("First name is required"),
    lastName: Yup.string()
      .min(3, "Last name must be at least 6 characters")
      .required("Last name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  return (
    <div
      className={`flex flex-col flex-grow lg:px-12 lg:py-12 p-8 overflow-hidden ${collapsed ? `${user?.role === UserRole.Admin ? "ml-20" : "md:ml-20"}` : `${user?.role === UserRole.Admin ? "ml-72" : "md:ml-72"}`} transition-all duration-300 pb-24`}>
      <div className="relative">
        {/* Header Section */}
        <div className="flex gap-4 items-center mb-3 justify-start lg:justify-start shadow-none lg:shadow-none">
          <button
            onClick={() => navigate(-1)}
            className="border-2 border-black-600 p-2 rounded-full lg:hidden z-50"
          >
            <ChevronLeft size={24} />
          </button>
          <h1 className="text-[20px] lg:text-3xl font-bold font-space-grotesk text-center md:text-left ml-[4rem] md:ml-1 lg:ml-0">
            Edit profile
          </h1>
        </div>
        <div className="lg:block hidden border-t border-[var(--Stroke,#EFEFF4)] mt-5"></div>
        {/* Drop Shadow on Mobile, Horizontal Line on Desktop */}
        <div className="border-t lg:border-t-0 border-[var(--Stroke,#EFEFF4)] shadow-md lg:shadow-none absolute bottom-[-0.25rem] h-24 w-[1000px] left-[-32px] right-0"></div>
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          firstName: user?.firstName || "",
          lastName: user?.lastName || "",
          email: user?.email || "",
          organization: user?.organizations[0].organization.name || "",
        }}
        validationSchema={updateUserSchema}
        onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <div className="mt-8 space-y-6 lg:w-1/2">

              <div className="flex w-full flex-col gap-1 items-start">
                <Label className="text-[#1B1B20] font-space-grotesk text-[18px] font-semibold leading-normal mb-1">
                  First Name
                </Label>
                <div className="w-full">
                  <Field
                    className={`px-4 py-2 text-[18px] font-space-grotesk w-full rounded-[0.5rem] placeholder-gray input-text focus:outline-none bg-transparent border-[1px] border-[#E0E0E5]"}`}
                    name="firstName"
                    type="text"
                    placeholder="Enter First name"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="p"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>

              <div className="flex w-full flex-col gap-1 items-start">
                <Label className="text-[#1B1B20] font-space-grotesk text-[18px] font-semibold leading-normal mb-1">
                  Last Name
                </Label>
                <div className="w-full">
                  <Field
                    className={`px-4 py-2 text-[18px] font-space-grotesk w-full rounded-[0.5rem] placeholder-gray input-text focus:outline-none bg-transparent border-[1px] border-[#E0E0E5]"}`}
                    name="lastName"
                    type="text"
                    placeholder="Enter Last name"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="p"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>

              <div className="flex w-full flex-col gap-1 items-start">
                <Label className="text-[#A4A4AA] font-space-grotesk text-[18px] font-semibold leading-normal mb-1">
                  Email
                </Label>
                <div className="w-full">
                  <Field
                    className={`px-4 py-2 w-full text-[18px] font-space-grotesk bg-[#EFEFF4] text-[#A4A4AA] rounded-[0.5rem] placeholder-gray input-text focus:outline-none border-[1px] border-[#E0E0E5]"}`}
                    type="email"
                    name="email"
                    placeholder="Enter your email address"
                    disabled
                  />
                  <ErrorMessage
                    name="email"
                    component="p"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>

              <div className="flex w-full flex-col gap-1 items-start">
                <Label className="text-[#A4A4AA] font-space-grotesk text-[18px] font-semibold leading-normal mb-1">
                  Organization
                </Label>
                <div className="w-full">
                  <Field
                    className={`px-4 py-2 w-full text-[18px] font-space-grotesk bg-[#EFEFF4] text-[#A4A4AA] rounded-[0.5rem] placeholder-gray input-text focus:outline-none border-[1px] border-[#E0E0E5]"}`}
                    type="organization"
                    name="organization"
                    placeholder="Enter your organization"
                    disabled
                  />
                  <ErrorMessage
                    name="email"
                    component="p"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>
            </div>

            <div className="mt-9 flex md:flex-row flex-col flex-wrap gap-4">
              <div className="flex justify-center items-center lg:w-36 bg-angular-gradient rounded-full p-0.5">
                <button
                  type="submit"
                  className="bg-rounded-full text-[18px] font-space-grotesk w-full font-semibold bg-white-a700 rounded-full p-1.5 hover:bg-light-angular-gradient"
                  disabled={isSubmitting}>
                  Update
                </button>
              </div>
              <button
                type="button"
                onClick={() => navigate(`/profile/${user?.userId}`)}
                className="py-1.5 px-10 rounded-full border-2 border-[#E0E0E5] text-[18px] font-semibold font-space-grotesk hidden lg:flex lg:items-center lg:justify-center">
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateProfile;
