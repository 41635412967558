import { Check, CheckCircle } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { TableColumn } from "~/interfaces/interfaces";
import { IError } from "~/interfaces/shared.interface";
import { networkService } from "~/services";
import CustomModal from "~/shared/components/customModal";
import CustomSelect from "~/shared/components/customSelect";
import LicenseCard from "~/shared/components/licenseCard";
import { License } from "~/shared/components/licensesTable";
import PageHeader from "~/shared/components/page-header.component";
import GenericTableWithSorting from "~/shared/components/table/table";
import Dropdown from "~/shared/components/ui/dropdown";
import NoProbe from "~/shared/components/ui/no-probe.component";
import { STATUS_COLOR, UserRole } from "~/shared/config";
import {
  CUSTOMER_DETAILS_API,
  DEVICE_REGISTRATION_STATUS,
  GET_ALL_AVAILABLE_LICENSES,
  GET_LICENSES_BY_ORG_ID,
  PROBE_DETAILS_API,
  UPDATE_DEVICE_ON_LICENSE,
} from "~/shared/constants/api";
import { MenuPlusIcon } from "~/shared/icon/menu-plus";
import { handleError } from "~/shared/utils/errors.util";
import { formatDate } from "~/shared/utils/helper.util";
import useAuthStore from "~/store/auth.store";
import useSidebarStore from "~/store/sidebar.store";

type SortDirection = "asc" | "desc" | null;
type SortField = "organizationName" | "expiryDate" | null;

const Licenses: React.FC = () => {
  const [selectedOrg, setSelectedOrg] = useState({
    name: "All Organizations",
    id: null,
  });
  const [licenseStatusFilter, setLicenseStatusFilter] =
    useState("All Licenses");
  const [membersSearch, setMembersSearch] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const downloadApp = searchParams.get("downloadApp");
  const [openDownloadAppModal, setOpenDownloadAppModal] = useState(
    downloadApp ? true : false
  );
  const [organizationOptions, setOrganizationOptions] = useState<
    { name: string; id: string | null }[]
  >([]);
  const [licenses, setLicenses] = useState<License[]>([]);
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);
  const [openLicenseModal, setOpenLicenseModal] = useState(false);
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const licenseOptions = [
    "All Licenses",
    "Active Licenses",
    "Inactive Licenses",
  ];
  const [probes, setProbes] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(7);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalLicenseResults, setTotalLicenseResults] = useState<{
    pageIndex: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
  }>();

  const [sortField, setSortField] = useState<SortField>(null);
  const [sortDirection, setSortDirection] = useState<SortDirection>(null);

  const { user, accessToken } = useAuthStore((state) => ({
    user: state.user,
    accessToken: state.accessToken,
  }));

  const adjustPageSize = () => {
    const offset = 120;
    const rowHeight = 100;
    const windowHeight = window.innerHeight;
    const numberOfRows = Math.floor((windowHeight - offset) / rowHeight);
    numberOfRows <= 3 ? setPageSize(5) : setPageSize(numberOfRows);
  };

  const handleRequestLicense = async () => {
    if (!user) return;
    try {
      const requestLicenseResponse = await networkService.post<any>(
        `${CUSTOMER_DETAILS_API}/${user?.organizationId}/request-new-license`,
        {}
      );
      if (requestLicenseResponse) {
        setOpenRequestModal(false);
        setOpenLicenseModal(true);
      }
    } catch (error) {
      const err = error as IError;
      err && toast.error(err.message);
    }
  };

  // Function to toggle sort direction
  const toggleSortDirection = (field: SortField) => {
    if (sortField === field) {
      // Toggle direction if the same field is clicked
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // Set default direction to ascending if a new field is clicked
      setSortField(field);
      setSortDirection("asc");
    }
  };

  useEffect(() => {
    adjustPageSize();
    window.addEventListener("resize", adjustPageSize);
    return () => {
      window.removeEventListener("resize", adjustPageSize);
    };
  }, []);

  useEffect(() => {
    if (!user) return;
    const getOrganization = async () => {
      try {
        const response = await networkService.get<any>(CUSTOMER_DETAILS_API);
        setOrganizationOptions(() => [
          { name: "All Organizations", id: null },
          ...(response.data.results?.map((org: any) => ({
            name: org.name,
            id: org.id,
          })) ?? []),
        ]);
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };
    user?.role === UserRole.Admin && getOrganization();
  }, [user]);

  useEffect(() => {
    const getAllLicenses = async () => {
      try {
        const url =
          user?.role === UserRole.Admin
            ? `${GET_ALL_AVAILABLE_LICENSES}?pageIndex=${pageNumber - 1}&pageSize=${pageSize}${licenseStatusFilter === "All Licenses" ? "" : `&isActive=${licenseStatusFilter === "Active Licenses"}`}${selectedOrg.id === null ? "" : `&organizationId=${selectedOrg.id}`}${sortField ? `&orderBy=${sortField}:${sortDirection}` : ""}`
            : `${GET_ALL_AVAILABLE_LICENSES}`;
        const response = await networkService.get<any>(url);
        setLicenses(response.data.results);
        setTotalLicenseResults(response.data.meta);
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };

    const getLicensesByOrganization = async () => {
      if (!user) return;
      try {
        const url =
          user?.role === UserRole.Admin
            ? `${GET_LICENSES_BY_ORG_ID}/${user?.organizationId}?pageIndex=${pageNumber - 1}&pageSize=${pageSize}${licenseStatusFilter === "All Licenses" ? "" : `&isActive=${licenseStatusFilter === "Active Licenses"}`}`
            : `${GET_LICENSES_BY_ORG_ID}/${user?.organizationId}`;
        const response = await networkService.get<any>(url);
        setLicenses(response.data.results);
        setTotalLicenseResults(response.data.meta);
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };

    if (user?.role === UserRole.Admin) {
      getAllLicenses();
    } else {
      getLicensesByOrganization();
    }
  }, [user, pageNumber, pageSize, licenseStatusFilter, selectedOrg, sortField, sortDirection]);

  const handleStatusChange = async (
    probeId: string,
    newStatus: string,
    organizationId: string
  ) => {
    if (!accessToken) {
      console.error("No token found");
      return;
    }
    try {
      await networkService.put<any>(
        `${DEVICE_REGISTRATION_STATUS}/${organizationId}/${probeId}/registration-status`,
        { registerStatus: newStatus }
      );
      toast.success("Registration status changed");
      setLicenses((prevLicenses) =>
        prevLicenses.map((license) =>
          license.device?.id === probeId
            ? {
              ...license,
              device: {
                ...license.device,
                registrationStatus: newStatus,
              },
            }
            : license
        )
      );
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    }
  };
  useEffect(() => {
    if (!user) return;
    const fetchProbes = async () => {
      try {
        const probesResponse = await networkService.get<any>(
          `${PROBE_DETAILS_API}${user?.role === UserRole.Admin ? "" : `/organization/${user?.organizationId}`}`
        );
        const probesData = probesResponse.data.results;
        setProbes(probesData);
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };

    fetchProbes();
  }, [user, licenses]);

  const onProbeSelect = async (id: string, licenseId: string) => {
    try {
      const res = await networkService.put<any>(
        `${UPDATE_DEVICE_ON_LICENSE}/${licenseId}`,
        {
          deviceId: id,
        }
      );
      if (res) {
        toast.success("Probe added successfully");
        navigate("/probes");
      }
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    }
  };

  const totalResults = totalLicenseResults?.totalItems || 0;
  const pageCount = Math.ceil(totalResults / pageSize);
  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage);
  };

  const colDefs: TableColumn[] = [
    {
      field: "licenseId",
      type: "string",
      name: "License Id",
      isSortable: false,
      valueFormatter: (item) => {
        return (
          <div className="min-w-[120px] items-center justify-center flex">
            <p className="text-sm max-w-[80px] truncate">{item.id}</p>
          </div>
        );
      },
    },
    {
      field: "status",
      isSortable: false,
      type: "string",
      name: "Status",
      valueFormatter: (item) => {
        return (
          <div
            className={`inline-flex flex-shrink-0 items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${!item?.isActive ? "bg-Peach-100 text-red-700 ring-red-600/20" : "bg-green-50 text-green-700 ring-green-600/20"}`}>
            {item?.isActive ? "Active" : "Inactive"}
          </div>
        );
      },
      comparator: (
        a: { isActive: boolean },
        b: { isActive: boolean },
        direction: "ascending" | "descending"
      ) => {
        return direction === "ascending"
          ? Number(a.isActive) - Number(b.isActive)
          : Number(b.isActive) - Number(a.isActive);
      },
    },
    // {
    //   field: "createdAt",
    //   isSortable: true,
    //   type: "string",
    //   name: "Created At",
    //   valueFormatter: (item) => {
    //     return (
    //       <div className="min-w-[100px]">
    //         <p className="text-sm">
    //           {item?.createdAt ? formatDate(item.createdAt) : "N/A"}
    //         </p>
    //       </div>
    //     );
    //   },
    //   comparator: (
    //     a: { createdAt: string },
    //     b: { createdAt: string },
    //     direction: "ascending" | "descending"
    //   ) => {
    //     const dateA = new Date(a.createdAt);
    //     const dateB = new Date(b.createdAt);
    //     return direction === "ascending"
    //       ? dateA.getTime() - dateB.getTime()
    //       : dateB.getTime() - dateA.getTime();
    //   },
    // },
    ...(user?.role === UserRole.Admin
      ? [
        {
          field: "organization",
          isSortable: true,
          type: "string",
          name: "Organization",
          valueFormatter: (item) => {
            return (
              <div className="min-w-[100px]">
                <p className="text-sm">{item.name}</p>
              </div>
            );
          },
          onSort: () => toggleSortDirection("organizationName"),
        },
      ]
      : []),
    {
      field: "expiryDate",
      isSortable: true,
      type: "string",
      name: "Expiry Date (mm/dd/yy)",
      valueFormatter: (item) => {
        return (
          <div className="min-w-[100px]">
            <p className="text-sm ml-10">
              {item.expiryDate ? formatDate(item.expiryDate) : "N/A"}
            </p>
          </div>
        );
      },
      onSort: () => toggleSortDirection("expiryDate"),
    },
    {
      field: "probe",
      isSortable: false,
      type: "string",
      name: "Probe ",
      valueFormatter: (item) => {
        return (
          <div className="min-w-[120px]">
            {user?.role !== UserRole.Admin ? (
              item.device ? (
                item?.device?.nickName
              ) : probes.length > 0 ? (
                <div className="w-[130px] -ml-5">
                  <CustomSelect
                    onChangeHandler={(value) => onProbeSelect(value, item.id)}
                    placeholder="Probe"
                    selectedValue={null}
                    className="block w-full border px-1 border-gray-300 rounded-md shadow-sm text-sm">
                    {probes.map((probe) => (
                      <option key={probe.id} value={probe.id}>
                        {probe.nickName}
                      </option>
                    ))}
                  </CustomSelect>
                </div>
              ) : (
                <div className="w-[130px] text-center">
                  {"No Probe Assigned"}
                </div>
              )
            ) : item.device ? (
              <div className="text-center">{item?.device?.nickName}</div>
            ) : (
              <div className="text-center">{"No Probe Assigned"}</div>
            )}
          </div>
        );
      },
    },
    ...(user?.role === UserRole.Admin
      ? [
        {
          field: "registrationStatus",
          isSortable: false,
          type: "string",
          name: "Probe Registration Status",
          valueFormatter: (item) => {
            return item.id ? (
              <div className="min-w-[120px]">
                <CustomSelect
                  selectedValue={item.registrationStatus}
                  onChangeHandler={(value) => {
                    handleStatusChange(item.id, value, item.orgId);
                  }}
                  placeholder="Select status"
                  className={`block w-full border px-1 border-gray-300 rounded-md shadow-sm text-sm ${STATUS_COLOR[item.registrationStatus as keyof typeof STATUS_COLOR]}`}>
                  <option value="REJECTED">Rejected</option>
                  <option value="PENDING">Pending</option>
                  <option value="APPROVED">Approved</option>
                </CustomSelect>
              </div>
            ) : (
              <div className="min-w-[120px] text-center">{"--"}</div>
            );
          },
        },
      ]
      : []),
    {
      field: "probeId",
      isSortable: false,
      type: "string",
      name: "Probe Serial Number",
      valueFormatter: (item) => {
        return (
          <div className="min-w-[100px]">
            <p className="text-sm text-center">{item.id ?? "--"}</p>
          </div>
        );
      },
    },
    {
      field: "model",
      isSortable: false,
      type: "string",
      name: "Model",
      valueFormatter: (item) => {
        return (
          <div className="min-w-[100px]">
            <p className="text-sm text-center">{item.modelNo ?? "--"}</p>
          </div>
        );
      },
    },
    // {
    //   field: "manufacturer",
    //   isSortable: false,
    //   type: "string",
    //   name: "Manufacturer",
    //   valueFormatter: (item) => {
    //     return (
    //       <div className="min-w-[100px]">
    //         <p className="text-sm text-center">{item.manufacturer ?? "--"}</p>
    //       </div>
    //     );
    //   },
    // },
    {
      field: "actions",
      isSortable: false,
      type: "string",
      name: "Probe Actions",
      valueFormatter: (item) => {
        return item.id ? (
          <div className="flex min-w-[120px] items-center justify-center">
            <Link
              to={`/probes/${item.id}`}
              className="text-indigo-600 hover:text-indigo-900">
              View details
            </Link>
            <Link
              to={`/probes/update-probe-details/${item.organization}/${item.id}`}>
              <img
                src="/edit.svg"
                alt="edit"
                className="ml-2 h-6 w-6"
                width={20}
                height={20}
              />
            </Link>
          </div>
        ) : user?.role !== UserRole.Admin ? (
          <div>
            <button
              className="text-center py-2 px-6 rounded-full bg-gray-900 text-white-a700 text-base font-space-grotesk"
              onClick={() =>
                navigate(
                  `/probes/update-probe-details/${user.organizationId}/new?licenseId=${item.licenseId}`
                )
              }>
              Add a Probe
            </button>
          </div>
        ) : (
          <div className="text-center">{"--"}</div>
        );
      },
    },
  ];

  const rowDefs: Record<string, any>[] = licenses.map((item) => ({
    licenseId: {
      id: item.id,
    },
    createdAt: {
      createdAt: item.createdAt,
    },
    organization: {
      name: item.subscription.organization.name,
    },
    expiryDate: {
      expiryDate: item.expiryDate,
    },
    status: {
      isActive: item.isActive,
    },
    registrationStatus: {
      id: item?.device?.id,
      orgId: probes.find((probe) => probe.id === item?.device?.id)?.organization
        .id,
      registrationStatus: probes.find((probe) => probe.id === item?.device?.id)
        ?.registrationStatus,
    },
    probe: {
      device: item.device,
      id: item.id,
    },
    probeId: {
      id: item.device?.externalDeviceId,
    },
    model: {
      modelNo: probes.find((probe) => probe.id === item?.device?.id)?.model
        .modelName,
    },
    manufacturer: {
      manufacturer: probes.find((probe) => probe.id === item?.device?.id)?.model
        ?.manufacturer?.name,
    },
    actions: {
      licenseId: item.id,
      id: probes.find((probe) => probe.id === item?.device?.id)?.id,
      organization: probes.find((probe) => probe.id === item?.device?.id)
        ?.organization.id,
    },
  }));

  return (
    <div
      className={`flex flex-col flex-grow px-10 py-5 mb-2 ${collapsed ? `${user?.role === UserRole.Admin ? "ml-20" : "md:ml-20"}` : `${user?.role === UserRole.Admin ? "ml-72" : "md:ml-72"}`} transition-all duration-300 pb-24`}>
      {/* request license modal */}
      <CustomModal
        isOpen={openLicenseModal}
        onClose={() => {
          setOpenLicenseModal(false);
        }}
        showCloseButton={true}>
        <div className="flex flex-col gap-3 items-center font-space-grotesk">
          {/* icon */}
          <div className="rounded-full p-2 bg-confirm_green_light">
            <div className="rounded-full p-2 bg-confirm_green_dark bg-opacity-20">
              <CheckCircle className="w-7 h-7 text-green-500" />
            </div>
          </div>
          {/* text */}
          <div className="w-full flex flex-col gap-1">
            <h1 className="text-center text-2xl font-semibold">
              Your Request has been sent!
            </h1>
            <p className="text-center text-base font-normal text-black-900 text-opacity-60 max-w-[450px] mt-4">
              We received your request and will get back to you by{" "}
              <span className="text-gray-900 font-semibold">email</span> at the
              earliest.
            </p>
          </div>
          <div className="w-full flex justify-around mt-2">
            <button
              className="text-center py-2 px-6 rounded-full bg-[#E9C3E2] text-base font-space-grotesk"
              onClick={() => {
                setOpenLicenseModal(false);
              }}>
              Close
            </button>
          </div>
        </div>
      </CustomModal>
      {/* confirmation modal */}
      <CustomModal
        isOpen={openRequestModal}
        onClose={() => {
          setOpenRequestModal(false);
        }}
        showCloseButton={true}>
        <div className="flex flex-col gap-3 items-center font-space-grotesk">
          {/* icon */}
          <div className="rounded-full p-2 bg-[#EDEDFA] font-sans">
            <div className="rounded-full p-2 bg-lavender-400 bg-opacity-50 flex items-center justify-center">
              <span className="text-[#6C5FC8] border-[#6C5FC8] border-2 rounded-full py-1 px-1.5 text-[10px] text-center font-bold">
                +1
              </span>
            </div>
          </div>
          {/* text */}
          <div className="w-full flex flex-col gap-1">
            <h1 className="text-center text-2xl font-semibold">
              Request new license?
            </h1>
            <p className="text-center text-base font-normal text-black-900 text-opacity-60 max-w-[450px] mt-4">
              You can request additional licenses for the HeartFocus Education
              App. Send a request and our team will come back to you quickly!
            </p>
          </div>
          <div className="w-full flex justify-between mt-4 px-4">
            <button
              className="text-center py-2 px-6 rounded-full text-base font-space-grotesk border border-zinc-900"
              onClick={() => {
                setOpenRequestModal(false);
              }}>
              Cancel
            </button>
            <button
              className="text-center py-2 px-6 rounded-full bg-[#E9C3E2] text-base font-space-grotesk"
              onClick={() => {
                handleRequestLicense();
              }}>
              Send Request
            </button>
          </div>
        </div>
      </CustomModal>
      {/* download the app modal  */}
      <CustomModal
        isOpen={openDownloadAppModal}
        onClose={() => {
          setOpenDownloadAppModal(false);
        }}
        showCloseButton={true}>
        <div className="flex flex-col gap-3 items-center font-space-grotesk">
          {/* icon */}
          {/* <div className="flex items-center gap-1 mt-5">
            <div className="flex flex-col items-center justify-center">
              <div className="rounded-full p-[5px] w-[30px] h-[30px] bg-[#B1E7C2] flex items-center justify-center text-[#22C55D]">
                <Check size={18} />
              </div>
              <p className="text-xs max-w-[50px] text-center min-h-[35px] font-space-grotesk">
                Sign up
              </p>
            </div>
            <span className="h-[2px] bg-[#B1E7C2] w-[30px] -mt-9" />
            <div className="flex flex-col items-center justify-center">
              <div className="rounded-full p-[5px] w-[30px] h-[30px] bg-[#B1E7C2] flex items-center justify-center text-[#22C55D]">
                <Check size={16} />
              </div>
              <p className="text-xs max-w-[50px] text-center min-h-[35px] font-space-grotesk">
                Attach a probe
              </p>
            </div>
            <span className="h-[2px] bg-[#E0E0E5] w-[30px] -mt-9" />
            <div className="flex flex-col items-center justify-center">
              <div className="rounded-full p-[5px] w-[30px] h-[30px] bg-[#E0E0E5] flex items-center justify-center text-[#E0E0E5]">
                <Check size={16} />
              </div>
              <p className="text-xs max-w-[50px] text-center min-h-[35px] font-space-grotesk">
                Download app
              </p>
            </div>
            <span className="h-[2px] bg-[#E0E0E5] w-[30px] -mt-9" />
            <div className="flex flex-col items-center justify-center">
              <div className="rounded-full p-[5px] w-[30px] h-[30px] bg-[#E0E0E5] flex items-center justify-center text-[#E0E0E5]">
                <Check size={16} />
              </div>
              <p className="text-xs max-w-[50px] text-center min-h-[35px] font-space-grotesk">
                Get started!
              </p>
            </div>
          </div> */}
          {/* text */}
          <div className="w-full flex flex-col gap-1">
            <h1 className="text-center text-2xl font-semibold mt-4">
              Probe Attached!
            </h1>
            <p className="text-center text-base font-normal text-black-900 text-opacity-60 max-w-[450px] mt-4">
              Now you can download HeartFocus App on your iPad directly on the
              Apple store
            </p>
          </div>
          <div className="w-full mt-4 px-4 flex items-center justify-center">
            <button
              className="text-center py-2 px-6 rounded-full bg-[#E9C3E2] text-base font-space-grotesk"
              onClick={() => {
                window.open(
                  import.meta.env.VITE_APP_STORE_URL,
                  "_blank",
                  "noopener,noreferrer"
                );
                setOpenDownloadAppModal(false);
              }}>
              Download App
            </button>
          </div>
        </div>
      </CustomModal>
      {/* <div className="lg:hidden flex border-b justify-center border-[var(--Stroke,#EFEFF4)]"><PageHeader title="Licenses" /></div> */}

      <div className="flex items-center justify-between mt-4">
        {/* Organization Filter */}
        {user?.role === UserRole.Admin && (
          <div className="flex items-center">
            <img
              src="/filter-icon.svg"
              alt="Filter Icon"
              width={20}
              height={20}
              className="mr-2"
            />
            <span className="text-neutral-900 font-medium font-space-grotesk">
              Filter by:
            </span>
            {user?.role === UserRole.Admin && (
              <div className="px-2">
                <Dropdown
                  options={organizationOptions}
                  onChange={setSelectedOrg}
                  renderOption={(option) => option.name}
                  selectedOption={selectedOrg}
                />
              </div>
            )}
            <div className="px-2">
              <Dropdown
                options={licenseOptions}
                onChange={setLicenseStatusFilter}
                renderOption={(option) => option}
                selectedOption={licenseStatusFilter}
              />
            </div>
          </div>
        )}
        {user?.role !== UserRole.Admin && (
          <div className="flex lg:flex-row gap-5 justify-between w-full lg:items-center pr-2 mt-5 lg:mt-1">
            <span className="lg:px-2 text-3xl font-space-grotesk font-bold">Licenses</span>
            <button
              className="hidden md:flex flex-row justify-center items-center text-base gap-2 w-[218px] h-[44px] bg-[#121217] shadow-md rounded-[30px] text-white-a700 font-space-grotesk"
              type="button"
              onClick={() => setOpenRequestModal(true)}
            >
              <span className="mr-1">
                {/* Icon container */}
                <MenuPlusIcon />
              </span>
              <span>Request license</span>
            </button>
          </div>
        )}
      </div>
      <div className="mt-8 md:px-2 flex flex-col gap-4 items-center justify-between h-full">
        {/* <LicenseTable licenses={filteredLicenses} /> */}
        {licenses.length === 0 ? (
          <NoProbe />
        ) : user?.role === UserRole.Admin ? (
          <GenericTableWithSorting
            column={colDefs}
            row={rowDefs}
            searchField="organizationName.name"
            searchTerm={membersSearch}
            showPagination={true}
            totalResults={totalResults}
            pageSize={pageSize}
            pageCount={pageCount}
            resetToFirstPage={false}
            handlePageChange={handlePageChange}
          />
        ) : (
          licenses.map((license) => {
            return (
              <LicenseCard
                licenseInfo={license}
                probeInfo={probes}
                user={user}
              />
            );
          })
        )}
        <button
          className="md:hidden fixed bottom-24 left-1/2 transform -translate-x-1/2 flex flex-row justify-center items-center px-4 py-2 text-lg gap-2 w-[221px] h-[48px] bg-[#121217] shadow-md text-white-a700 shadow-black/40 rounded-[30px] font-space-grotesk z-50"
          type="button"
          onClick={() => setOpenRequestModal(true)}
        >
          <span className="mr-1">
            <MenuPlusIcon />
          </span>
          <span>Request license</span>
        </button>
      </div>
    </div>
  );
};

export default Licenses;