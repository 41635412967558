import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { CardDescription } from "~/shared/components/ui/card";
import { Label } from "~/shared/components/ui/label";
import { IError } from "~/interfaces/shared.interface";
import { LOGIN_API, PROFILE_INFO_API, UPDATE_PASSWORD } from "~/shared/constants/api";
import axios, { AxiosError } from "axios";
import { handleError } from "~/shared/utils/errors.util";
import HeartFocusIcon from "~/shared/icon/heartfocus.icon";
import TabletIcon from "~/shared/icon/tablet.icon";
import { AlertCircle, Eye, EyeOff } from "lucide-react";
import { networkService } from "~/services";
import { UserRole } from "~/shared/config";
import useAuthStore from "~/store/auth.store";
import { LOGIN_ERROR } from "~/shared/constants/errormessage";
import brandImg from "../../../assets/images/brand-image.png"


const UpdatePassword: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const { user, setAuthState, updateUserInfo } = useAuthStore((state) => state);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  // const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // useEffect(() => {
  //   if (!user) return;

  //   if(!token) {
  //     navigate("/login");
  //   }

  //   if (user?.role === UserRole.Admin) {
  //     navigate("/home");
  //   } else {
  //     navigate("/dashboard");
  //     // if (user?.organizationId) {
  //     // 	navigate("/dashboard");
  //     // } else {
  //     // 	navigate("/onboarding/organization");
  //     // }
  //   }
  // }, [user, navigate, token]);

  useEffect(() => {
    if (!user) return;

    if (!token) {
      navigate("/login");
      return;
    }

    if (user) {
      navigate(user.role === UserRole.Admin ? "/home" : "/dashboard");
    }
  }, [user, token, navigate]);

  const togglePasswordVisibility = (field: "password") => {
    if (field === "password") {
      setShowPassword((prev) => !prev);
    }
  };

  const getOrganizationDetails = async (userId: string) => {
    try {
      const response = await networkService.get<any>(
        `${PROFILE_INFO_API}/${userId}`
      );
      return response;
    } catch (error) {
      const err = error as IError;
      handleError({ error: err, message: err.message, level: "error" });
      err?.message && toast.error(err.message);
      return null;
    }
  };

  const handleLogin = async (values: { email: string; password: string }) => {
    setLoading(true);
    try {
      const response = await networkService.post<{
        data: {
          accessToken: string;
          userId: string;
          role: UserRole;
        };
      }>(LOGIN_API, values);

      setAuthState({
        accessToken: response.data.accessToken,
        user: {
          userId: response.data.userId,
          role: response.data.role,
          organizationId: "",
        },
      });

      toast.success("User logged in successfully");

      if (response.data.role === UserRole.Admin) {
        navigate("/home");
      } else {
        const userData = await getOrganizationDetails(response.data.userId);
        if (userData.data.organizations.length === 0) {
          navigate("/onboarding/organization");
        } else {
          updateUserInfo({
            organizationId: userData.data.organizations[0]?.organizationId,
          });
        }
      }
    } catch (error: any) {
      setErrorMessage(error.message || LOGIN_ERROR);
      handleError({ error: error, message: error.message, level: "error" });
    } finally {
      setLoading(false);
    }
  };

  const changePassword = async (password: string) => {
    if (!token) return;
    try {
      const response = await axios.post(`${UPDATE_PASSWORD}/${token}`, {
        newPassword: password,
      });
      if (response) {
        const { email } = response.data.data;
        console.log(response.data.data);
        console.log("email", email);
        console.log("password", password);
        await handleLogin({ email, password });
        toast.success("Your password has been successfully updated!");
      }
    } catch (err: any) {
      const error = err as AxiosError<IError>;
      handleError({ error: error, message: error.message, level: "error" });
    }
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(6, "Password must be at least 6 characters long.")
      .required("This field is required"),
  });

  return (
    <div className="flex min-h-screen font-space-grotesk">
      <div className="flex md:w-2/5 w-full flex-col items-center px-6 lg:px-8 z-10 py-10">
        <div className="font-sans text-2xl font-bold leading-tight text-center bg-radial-gradient bg-clip-text">
          <HeartFocusIcon />
        </div>

        <div className="w-full xl:w-4/5 flex flex-col mt-auto">
          <Formik
            enableReinitialize
            initialValues={{ password: "" }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              changePassword(values.password);
            }}>
            {({ handleChange, values, errors, touched }) => (
              <Form className="flex flex-col gap-12">
                <CardDescription className="text-black flex flex-col gap-2">
                  <span className="text-4xl font-bold">Set new password</span>
                  <span className="block text-base font-normal">
                    Please enter a new password for your account.
                  </span>
                </CardDescription>
                <div className="flex flex-col gap-7">
                  <div className="flex w-full flex-col gap-1 items-start">
                    <Label className="text-[#1B1B20] font-space-grotesk text-base font-semibold leading-normal">
                      New Password
                    </Label>
                    <p className="text-xs mb-1 text-inactive-text">Must be at least 6 characters long.</p>
                    <div className="w-full">
                      <div className="relative">
                        <Field
                          name="password"
                          type={showPassword ? "text" : "password"}
                          className={`px-4 py-2.5 w-full rounded-[0.5rem] placeholder-gray input-text focus:outline-none bg-transparent ${errors.password && touched.password ? "border border-[#F87171] shadow-[0_0_6px_1.5px_#F87171B2]" : "border-[1px] border-[#D0D0D6]"}`}
                          placeholder="Enter the new password"
                          value={values.password}
                          onChange={handleChange}
                        />
                        <button
                          type="button"
                          onClick={() => togglePasswordVisibility("password")}
                          className="absolute right-3 top-3 text-gray-500"
                          aria-label={showPassword ? "Hide password" : "Show password"}
                        >
                          {showPassword ? <Eye size={20} /> : <EyeOff size={20} />}
                        </button>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        children={(errorMessage) => (
                          <div className="text-[#F87171] text-xs mt-2 flex gap-1 items-center">
                            <AlertCircle size={14} />
                            <span>{errorMessage}</span>
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex w-full justify-center items-center bg-angular-gradient rounded-full p-0.5 mt-6">
                    <button
                      type="submit"
                      className="w-full font-semibold text-[18px] bg-white-a700 rounded-full p-1.5 hover:bg-light-angular-gradient">
                      Save
                    </button>
                  </div>

                  <div className="flex flex-col gap-7 mt-6">
                    <hr className="w-full border-t border-gray-300" />
                    <div className="w-full justify-center">
                      <Link
                        to="/login"
                        className="text-[#000] font-space-grotesk text-[16px] md:text-sm font-normal leading-[24px]">
                        Don't want to reset password?{" "}
                        <span className="font-semibold underline">Login</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* terms and conditions */}
        <div className="w-full xl:w-4/5 flex flex-col mt-auto 2xl:items-center">
          <p className="text-xs text-gray-700 text-center">
            By continuing, you agree to Heartfocus's{" "}
            <span className="inline-block">
              <a
                href="https://www.heartfocus.ai/portal-terms-conditions"
                className="text-gray-700 hover:underline" target="_blank">
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="https://www.heartfocus.ai/education-privacy-policy"
                className="text-gray-700 hover:underline" target="_blank">
                Privacy Policy
              </a>
              .
            </span>
          </p>
        </div>
      </div>

      {/* image */}
      <div className="w-3/5 h-screen hidden md:block relative overflow-hidden">
        <img
          src="/Gradientcropped.png"
          alt="Background Image"
          className="w-full h-full"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center z-10 md:py-12 2xl:py-0">
          <div className="text-4xl font-bold mb-2 pt-10">
            We believe No Heart Can Wait&trade;
            {/* <div
              style={{
                background: "black",
                display: "inline-block",
                padding: "0.15rem 0.5rem",
                marginLeft: "0.5rem",
              }}>
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#e3e1f5] via-[#d4d3f0] to-[#b3b1e7]">
                No Hearts Can Wait.
              </span>
            </div> */}
          </div>
          <p className="text-lg mb-6">
            Join us in bringing heart health to the whole world.
          </p>
          <img
            src={brandImg}
            alt="Brand Image"
            className="xl:w-[70%] md:w-[95%]" // Tailwind class to set width to 80%
          />
          <p className="pb-5">
            Learn more about HeartFocus{" "}
            <a href="https://heartfocus.ai" className="underline" target="_blank">
              here.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
