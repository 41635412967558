import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { TableColumn } from "~/interfaces/interfaces";
import AlphabeticalSortIcon from "../../icon/alphabetical-sort-icon";
import Loader from "../ui/customLoader";
import PaginationComponent from "../pagination.component";
import { useTableSort } from "./useTableSort";
import { useTableSearch } from "./useTableSearch";

type GenericTablePropsBase = {
  column: TableColumn[];
  row: Record<string, any>[];
  searchField: string;
  searchTerm: string;
  isSortingApiDriven?: boolean;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  handleSorting?: (sortConfig: any) => void;
  rowItems?: any;
  selectedItem?: any;
  onRowClick?: any;
  enableRowSelection?: boolean;
};

type GenericTablePropsWithPagination = GenericTablePropsBase & {
  showPagination: true;
  handlePageChange: (newPage: number) => void;
  totalResults: number;
  pageSize: number;
  pageCount: number;
  resetToFirstPage: boolean;
};

type GenericTablePropsWithoutPagination = GenericTablePropsBase & {
  showPagination?: false;
  handlePageChange?: never;
  totalResults?: never;
  pageSize?: never;
  pageCount?: never;
  resetToFirstPage?: never;
};

type GenericTableProps =
  | GenericTablePropsWithPagination
  | GenericTablePropsWithoutPagination;

const TableHead = ({
  columns,
  handleSort,
  enableRowSelection,
  allRowsSelected,
  toggleSelectAll,
}: {
  columns: TableColumn[];
  handleSort: any;
  enableRowSelection: boolean;
  allRowsSelected: boolean;
  toggleSelectAll: () => void;
}) => (
  <thead>
    <tr className="bg-white">
      {enableRowSelection && (
        <th className="whitespace-nowrap py-4 pl-4 bg-[#EFEFF4]">
          <input
            type="checkbox"
            checked={allRowsSelected}
            onChange={toggleSelectAll}
          />
        </th>
      )}
      {columns.map((item, index) => (
        <th
          key={index}
          scope="col"
          className="whitespace-nowrap py-4 px-4 bg-[#EFEFF4]">
          {item.isSortable ? (
            <button
              type="button"
              onClick={() => item.onSort()}
              className="flex items-center gap-1 text-center text-sm font-bold hover:text-primary w-full">
              <div>
                {item?.nameFormatter ? item?.nameFormatter() : item.name}
              </div>
              {item.Icon ? <item.Icon /> : <AlphabeticalSortIcon />}
            </button>
          ) : (
            <div className="flex items-center gap-0.5 space-x-3 text-sm hover:text-primary font-bold w-full text-center justify-center">
              {item?.nameFormatter ? item?.nameFormatter() : item.name}
            </div>
          )}
        </th>
      ))}
    </tr>
  </thead>
);

const TableBody = ({
  columns,
  items,
  selectedItem,
  onRowClick = () => {},
  inViewRef,
  enableRowSelection,
  selectedRows,
  toggleRowSelection,
}: {
  columns: TableColumn[];
  items: Record<string, any>[];
  selectedItem: any;
  onRowClick: any;
  inViewRef: (node?: Element | null) => void;
  enableRowSelection: boolean;
  selectedRows: Record<string, any>[];
  toggleRowSelection: (row: Record<string, any>) => void;
}) => (
  <tbody className="rounded-md bg-white divide-y">
    {items.map((item, idx) => (
      <tr
        key={idx}
        className="cursor-pointer rounded-md transition-colors duration-300">
        {enableRowSelection && (
          <td className="py-4 pl-4">
            <input
              type="checkbox"
              checked={selectedRows.includes(item)}
              onChange={() => toggleRowSelection(item)}
            />
          </td>
        )}
        {columns.map((col, index) => (
          <td
            ref={index === columns.length - 1 ? inViewRef : null}
            key={index}
            className="py-4 px-4 whitespace-nowrap text-sm text-gray-500"
            onClick={() => {
              if (selectedItem && selectedItem.id === item["row"].id) {
                onRowClick(null);
              } else {
                onRowClick(item);
              }
            }}>
            {col.valueFormatter(item[col.field])}
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);

const TableFooter = ({
  showPagination,
  handlePageChange,
  totalResults,
  pageSize,
  pageCount,
  resetToFirstPage,
}: {
  showPagination: boolean;
  handlePageChange: (newPage: number) => void;
  totalResults: number;
  pageSize: number;
  pageCount: number;
  resetToFirstPage: boolean;
}) => {
  if (!showPagination) return null;

  return (
    <div className="border-t px-4 py-2 bg-zinc-100">
      <PaginationComponent
        onPageChange={handlePageChange}
        totalRows={totalResults}
        rowsPerPage={pageSize}
        pageCount={pageCount}
        resetToFirstPage={resetToFirstPage}
      />
    </div>
  );
};

const GenericTableWithSorting: React.FC<GenericTableProps> = ({
  column,
  row,
  searchField,
  searchTerm,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  selectedItem,
  onRowClick,
  showPagination,
  totalResults,
  pageSize,
  pageCount,
  handlePageChange,
  resetToFirstPage,
  enableRowSelection = false,
}) => {
  const { sortedItems, handleSort } = useTableSort(row, column);
  const filteredItems = useTableSearch(searchField, searchTerm, sortedItems);
  const [inViewRef, inView] = useInView();
  const [selectedRows, setSelectedRows] = useState<Record<string, any>[]>([]);

  const toggleRowSelection = (row: Record<string, any>) => {
    setSelectedRows((prev) =>
      prev.includes(row) ? prev.filter((item) => item !== row) : [...prev, row]
    );
  };

  const toggleSelectAll = () => {
    if (selectedRows.length === filteredItems.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(filteredItems);
    }
  };

  const allRowsSelected = selectedRows.length === filteredItems.length;

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  return (
    <div className="font-space-grotesk border rounded-lg overflow-hidden flex flex-col w-full">
      <div className="overflow-scroll flex-grow">
        <table className="w-full rounded-md">
          <TableHead
            columns={column}
            handleSort={handleSort}
            enableRowSelection={enableRowSelection}
            allRowsSelected={allRowsSelected}
            toggleSelectAll={toggleSelectAll}
          />
          <TableBody
            columns={column}
            items={filteredItems}
            selectedItem={selectedItem}
            onRowClick={onRowClick}
            inViewRef={inViewRef}
            enableRowSelection={enableRowSelection}
            selectedRows={selectedRows}
            toggleRowSelection={toggleRowSelection}
          />
        </table>
        {isFetchingNextPage && (
          <div className="flex w-full justify-center py-4 text-gray-500">
            <Loader />
          </div>
        )}
      </div>
      <TableFooter
        showPagination={showPagination}
        handlePageChange={handlePageChange}
        totalResults={totalResults}
        pageSize={pageSize}
        pageCount={pageCount}
        resetToFirstPage={resetToFirstPage}
      />
    </div>
  );
};

export default GenericTableWithSorting;
