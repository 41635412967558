import { useNavigate } from "react-router-dom";
import { WarningIcon } from "../icon/warning-icon";
import { getFullDate } from "../utils/helper.util";

interface LicenseCardProps {
  licenseInfo: any;
  probeInfo: any;
  user?: any;
}

const LicenseCard: React.FC<LicenseCardProps> = ({
  licenseInfo,
  probeInfo,
  user,
}) => {
  const navigate = useNavigate();
  const probe = probeInfo.find((probe) => probe.id === licenseInfo?.device?.id);
  return (
    <div className="border shadow-md w-full rounded-[10px] px-6 py-7 md:py-7 md:px-8 font-space-grotesk md:min-h-[250px] md:max-w-full">
      {/* license info */}
      <section className="flex flex-col gap-4 pb-5">
        <h1 className="flex justify-between font-bold text-2xl">
          <span>
            {licenseInfo?.subscription?.plan?.name ??
              `License-${licenseInfo?.id}`}
          </span>
          <span className="bg-[#F2FBF8] border border-[#A9E6D3] text-[#4BB69C] text-sm font-medium px-2 py-1.5 rounded-sm ml-auto h-9">
            Active
          </span>
        </h1>
        <div className="flex md:flex-row flex-col justify-between text-zinc-600 font-normal">
          <span className="text-left flex gap-1 max-w-[300px] text-wrap">
            Id: {licenseInfo?.id}
          </span>
          <span className="lg:flex lg:flex-col mt-2 md:mt-0 md:text-right">
            <span>Expires on {' '}</span>
            <span className="font-semibold">{licenseInfo?.expiryDate
              ? getFullDate(licenseInfo?.expiryDate)
              : "Not Available"}
            </span>
          </span>
        </div>
      </section>
      {/* probe info */}
      <section className="border-t min-h-[100px] pt-5">
        {licenseInfo?.device ? (
          <div className="flex flex-col justify-between min-h-[80px]">
            <div className="flex flex-col gap-2 font-medium text-base">
              <p>
                {`${probe?.nickName} attached (${probe?.model?.manufacturer?.name}, ${probe?.model?.modelName})`}
              </p>
              <p className="font-normal text-xs text-gray-500">{`Serial Number : ${probe?.externalDeviceId}`}</p>
            </div>
            <div className="flex md:flex-row flex-row mt-5 md:mt-0 md:ml-auto gap-4 md:gap-3">
              <button
                className="text-center font-medium px-4 rounded-full bg-white-a700 text-gray-900 lg:text-[14px] max-[400px]:text-[13px] max-[380px]:text-[12px] md:text-base border border-gray-300"
                onClick={() => {
                  navigate(
                    `/probes/update-probe-details/${user.organizationId}/${probe.id}`
                  );
                }}>
                Edit probe
              </button>
              <div className="flex justify-center items-center bg-angular-gradient rounded-full p-0.5">
                <button
                  type="submit"
                  className="bg-rounded-full w-[121px] h-[34px] text-[14px] font-space-grotesk font-semibold bg-white-a700 rounded-full p-1.5 hover:bg-light-angular-gradient"
                  onClick={() =>
                    navigate(`/probes/${probe.id}`)
                  }>
                  View details
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-between min-h-[80px] gap-[22px]">
            <div className="flex gap-2">
              <WarningIcon />
              <span className="text-[#E27D55]">No probe attached</span>
            </div>
            <div className="lg:flex lg:justify-end">
              <div className="flex justify-center items-center bg-angular-gradient rounded-full p-0.5 w-[128px]">
                <button
                  type="submit"
                  className="bg-rounded-full w-[128px] h-[34px] text-[14px] font-space-grotesk font-semibold bg-white-a700 rounded-full p-1.5 hover:bg-light-angular-gradient"
                  onClick={() =>
                    navigate(
                      `/probes/update-probe-details/${user.organizationId}/new?licenseId=${licenseInfo?.id}`
                    )
                  }>
                  Attach probe
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default LicenseCard;
