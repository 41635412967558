import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import { Check, X } from "lucide-react";
import clsx from "clsx";

interface ValidationRule {
  rule: (value: string) => boolean;
  message: string;
}

interface GenericValidationInputProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  onValidationChange: (isValid: boolean) => void;
  onFindItClick?: () => void;
  name: string;
  placeholder: string;
  validationRules: ValidationRule[];
  fieldClassName?: string;
}

const GenericValidationInput: React.FC<GenericValidationInputProps> = ({
  setFieldValue,
  onValidationChange,
  onFindItClick,
  name,
  placeholder,
  validationRules,
  fieldClassName,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [validationStatus, setValidationStatus] = useState<Record<string, boolean>>({});

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setInputValue(value);

    // Update Formik Field value
    setFieldValue(name, value);

    // Evaluate validation rules
    const updatedValidationStatus = validationRules.reduce((acc, { rule }, index) => {
      acc[index] = rule(value);
      return acc;
    }, {} as Record<string, boolean>);

    setValidationStatus(updatedValidationStatus);

    // Determine if all rules are valid
    const allValid = Object.values(updatedValidationStatus).every((status) => status);
    onValidationChange(allValid);
  };

  return (
    <div>
      {/* Input Field */}
      <Field
        id={name}
        name={name}
        type="text"
        placeholder={placeholder}
        className={clsx(
          "font-space-grotesk px-4 py-3 w-full rounded-lg border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none",
          fieldClassName // Allow parent to override or add styles
        )}
        onChange={handleInputChange}
      />
      <ErrorMessage
        name={name}
        component="p"
        className="text-red-500 text-xs mt-2"
      />

      {/* Validation Messages */}
      {inputValue.length > 0 && (
        <div className="mt-2 text-sm">
          {validationRules.map(({ message }, index) => (
            <p
              key={index}
              className={`font-space-grotesk flex items-center gap-1 ${
                validationStatus[index] ? "text-green-500" : "text-red-500"
              }`}
            >
              {validationStatus[index] ? <Check /> : <X />}
              {message}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default GenericValidationInput;
