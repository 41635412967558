import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import networkService from "~/services/network.service";
import PageHeader from "~/shared/components/page-header.component";
import { PROFILE_INFO_API } from "~/shared/constants/api";
import { handleError } from "~/shared/utils/errors.util";
import useAuthStore from "~/store/auth.store";
import useSidebarStore from "~/store/sidebar.store";
import { UserRole } from "~/shared/config";
import { Label } from "~/shared/components/ui/label";
import { AlertCircle, ChevronLeft, Eye, EyeOff } from "lucide-react";

export default function Update() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const accessToken = useAuthStore((state) => state.accessToken);
  const user = useAuthStore((state) => state.user);
  const userId = user?.userId;
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  useEffect(() => {
    if (!userId || !accessToken) {
      return;
    }
    const fetchUserDetails = async () => {
      try {
        const response = await networkService.get<any>(
          `${PROFILE_INFO_API}/${userId}`
        );
        setUserData(response.data);
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };

    fetchUserDetails();
  }, [userId, accessToken]);

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    if (!userId || !accessToken) return;

    const updateData = {
      email: userData.email,
      currentPassword: values.oldPassword,
      newPassword: values.newPassword || undefined,
    };

    try {
      await networkService.put<any>(
        `${PROFILE_INFO_API}/${userId}`,
        updateData
      );
      toast.success("User updated successfully");
      navigate(-1);
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  const changePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required("This field is required"),
    newPassword: Yup.string()
      .min(6, "Password must be at least 6 characters long.")
      .notOneOf(
        [Yup.ref("oldPassword")],
        "New password must be different from the old password"
      )
      .required("This field is required"),
  });

  return (
    <div
      className={`flex flex-col flex-grow lg:px-12 lg:py-12 p-8 overflow-hidden ${collapsed ? `${user?.role === UserRole.Admin ? "ml-20" : "md:ml-20"}` : `${user?.role === UserRole.Admin ? "ml-72" : "md:ml-72"}`} transition-all duration-300 pb-24`}>
      {/* <div className="flex gap-4 items-center mb-4 justify-start lg:justify-start">
        <button onClick={() => navigate(-1)} className="border-2 border-black-600 p-2 rounded-full lg:hidden">
          <ChevronLeft size={24} />
        </button>
        <h1 className="text-[20px] lg:text-3xl font-bold font-space-grotesk text-center md:text-left ml-10 lg:ml-0">Change Password</h1>
      </div>
      <div className="border-t border-[var(--Stroke,#EFEFF4)]"></div> */}

      <div className="relative">
        {/* Header Section */}
        <div className="flex gap-4 items-center mb-3 justify-start lg:justify-start shadow-none lg:shadow-none">
          <button
            onClick={() => navigate(-1)}
            className="border-2 border-black-600 p-2 rounded-full lg:hidden z-50"
          >
            <ChevronLeft size={24} />
          </button>
          <h1 className="text-[20px] lg:text-3xl font-bold font-space-grotesk text-center md:text-left ml-10 md:ml-1 lg:ml-0">
            Change password
          </h1>
        </div>
        <div className="lg:block hidden border-t border-[var(--Stroke,#EFEFF4)] mt-5"></div>
        {/* Drop Shadow on Mobile, Horizontal Line on Desktop */}
        <div className="border-t lg:border-t-0 border-[var(--Stroke,#EFEFF4)] shadow-md lg:shadow-none absolute bottom-[-0.25rem] h-24 w-[1000px] left-[-32px] right-0"></div>
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
        validationSchema={changePasswordSchema}
        onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <div className="lg:w-1/2 mt-9 space-y-6">

              <div className="flex w-full flex-col items-start gap-1">
                <Label className="text-[#1B1B20] font-space-grotesk text-[18px] font-semibold mb-1">
                  Old password
                </Label>
                <div className="relative w-full">
                  <Field
                    className={`px-4 py-2.5 w-full font-space-grotesk text-[18px] rounded-[0.5rem] placeholder-gray input-text focus:outline-none bg-transparent border-[1px] border-[#D0D0D6]"
                          }`}
                    type={showOldPassword ? "text" : "password"}
                    name="oldPassword"
                    placeholder="Enter old password"
                  />
                  <button
                    type="button"
                    onClick={() => setShowOldPassword((prev) => !prev)}
                    className="absolute right-3 top-3 text-gray-500"
                    aria-label={showOldPassword ? "Hide password" : "Show password"}
                  >
                    {showOldPassword ? <Eye size={20} /> : <EyeOff size={20} />}
                  </button>
                </div>
                <ErrorMessage
                  name="oldPassword"
                  component="p"
                  children={(errorMessage) => (
                    <div className="text-[#F87171] text-xs mt-2 flex gap-1 items-center">
                      <AlertCircle size={14} />
                      <span>{errorMessage}</span>
                    </div>
                  )}
                />
              </div>


              <div className="flex w-full flex-col items-start gap-1">
                <Label className="text-[#1B1B20] font-space-grotesk text-[18px] font-semibold mb-1">
                  New password
                </Label>
                <div className="relative w-full">
                  <Field
                    className={`px-4 py-2.5 w-full font-space-grotesk text-[18px] rounded-[0.5rem] placeholder-gray input-text focus:outline-none bg-transparent border-[1px] border-[#D0D0D6]"
                          }`}
                    type={showNewPassword ? "text" : "password"}
                    name="newPassword"
                    placeholder="Enter new password"
                  />
                  <button
                    type="button"
                    onClick={() => setShowNewPassword((prev) => !prev)}
                    className="absolute right-3 top-3 text-gray-500"
                    aria-label={showNewPassword ? "Hide password" : "Show password"}
                  >
                    {showNewPassword ? <Eye size={20} /> : <EyeOff size={20} />}
                  </button>
                </div>
                <ErrorMessage
                  name="newPassword"
                  component="p"
                  children={(errorMessage) => (
                    <div className="text-[#F87171] text-xs mt-2 flex gap-1 items-center">
                      <AlertCircle size={14} />
                      <span>{errorMessage}</span>
                    </div>
                  )}
                />
              </div>
            </div>

            <div className="mt-9 flex md:flex-row flex-col flex-wrap gap-4">
              <div className="flex justify-center items-center lg:w-36 bg-angular-gradient rounded-full p-0.5">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="flex justify-center items-center gap-2 bg-rounded-full text-[18px] font-semibold sm:text-base md:text-base font-space-grotesk w-full bg-white-a700 rounded-full p-2 hover:bg-light-angular-gradient">
                  {isSubmitting ? "Updating..." : "Update"}
                </button>
              </div>
              <button
                type="button"
                onClick={() => navigate(`/profile/${user?.userId}`)}
                className="py-1.5 px-10 rounded-full border-2 border-[#E0E0E5] text-[18px] font-semibold font-space-grotesk hidden lg:flex lg:items-center lg:justify-center">
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
